<template>
    <v-app>
        <div class="limiter">
            <div id="bg-css">
                <svg id="Camada_1" data-name="Camada 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 -20 1920 1011">
                    <path class="bottom-splash"
                        d="M0,1077.47V822.28c4.34-4.63,9.27-3.45,13.84-1.22C56.76,842,94.19,826.4,129.62,797.41c18.95-12.17,30.81-31.76,40.41-53.53,9.83-22.3,20.94-43.39,37.48-59.91,29.19-16.75,46.06-14.33,68.5,9.75,13.81,23.67,22.82,48.56,14.05,77.82a43,43,0,0,1-1.39,6.19,80.44,80.44,0,0,0-.06,43.82,77.24,77.24,0,0,0,14.78,22.05c13.6,11.58,26.61,5.8,39.53-1.83a29,29,0,0,1,4.22-3.35c25.81-20.16,40.41-51,56.18-80.74,3.54-6.68,7.11-13.35,10.45-20.19,23.36-42,49.48-80.71,89.83-102.28,30.63-10.85,58.45-6.88,81.72,21.15,7.53,11.36,16.56,21.17,24.36,32.32,16,22.81,33,28,57.56,18.37,27.31-10.69,50.53-29.24,71.46-52,12.42-10.39,24.55-21.37,39.25-27.36a21,21,0,0,1,5.7-1.59c43.55-2,68.89,40.93,51.89,87.94-4.81,13.3-11.16,25.47-20.71,35-38.44,44.83-86.14,45.84-135.37,39-4.79-.67-9.49-2.71-14.42-2.62-18.89-3.05-36.53-11.3-54.51-18.12-30.18-11.44-60.38-18.2-90.6-.29-35.29,18.69-57,54-55.57,90.8,1.6,42.26,28.73,82.88,64,96.35,27.72,10.59,51.73-2.67,75.86-15.55,14.33-5.24,27.41-14.38,42.1-18.52,46.09-14.14,83.58-.07,111.38,46.34,4.58,7.65,10.14,14.47,15.12,21.8,19.49,26.21,44.74,23,69.92,18.19,28.41-5.39,56.1-16.21,85.44-14.36,10.48.66,20.84,1.46,30.74,5.91,18,7.94,33.84,19.76,44.81,38.4,7,12,15.07,24.68,9.09,41.12a8.05,8.05,0,0,1-6.34,2.2q-493.33,0-986.58-.09C13.28,1079.51,6.37,1081.5,0,1077.47Z" />
                    <path class="top-splash"
                        d="M1920,69.71V454.65c-4.39,2.77-8.51.78-12.1-1.78-37.76-27-74.25-21.43-110,5.55-16,15.34-31.15,32.08-49.07,44.62-17.78,10.41-36.77,15.43-56.43,17.12a33.41,33.41,0,0,1-25.94-8.38c-21.83-19.8-31-46.54-33.73-78.22-4-47.49-22.16-67-70.22-79.21-27.5-4.79-52.82,6.07-78.39,15-19.75,8.3-36.94,22-54.37,35.24-24.28,18.45-50.24,32.35-78.82,39-24,.85-42.9-8.57-54.9-34.7-14.75-32.13-8.07-61.53,7.5-89.41,13.11-23.49,32.49-38.8,54-50.54,16.88-9.22,35.32-12.26,53-18.45,3.89-1.36,7.89-1.1,11.87-1.12,11.94-.1,23.93,1,35.68-2.66,10-.59,19.75,1.53,29.6,2.76,32.91,6,63.89,19,93.84,35.65,6,3.37,11.68,7.87,18.5,9.05,35.74,18.88,72.1,27.63,110.09,9.22,17.91-13.29,32.74-28.91,27.81-57.12-4.59-26.29-23.58-44.77-50.48-47.85-19.86-2.28-36.61-10.34-49.8-28.06-15.42-21.1-20.43-44.51-9.25-69.95,10.22-23.27,28-30.64,49.48-29.34,9.35.57,18,4.72,27.16,5.64,24.14,8.3,47.28,20,71.24,29,45,17,85.88,9.42,123.14-25C1905.37,75.21,1910.46,66.44,1920,69.71Z" />
                    <path class="center-splash"
                        d="M1215.13,649.44c-25.56,10.76-51.41,13.52-77.72,4-4.29-4.07-12.54.75-14.41-8.78-34.79-39.32-73.06-43.6-115.43-12.93-14.43,26.12-32.38,47.95-58.37,57.1-16.6,5.84-32.6,2.44-44.2-15.14C871.5,630.2,882,591.32,934.6,564c10.08-14,22.5-16,36.51-9.67l32.55.13a370.25,370.25,0,0,0,82.12-.07l.16.24c31.76-7.21,56.47-28,77.75-55.36,8-21.95,26.82-32.79,46.79-27,57,17.34,74.23,63.11,49.08,130.06-2.32,3.46-.07,7.69-1.38,11.34C1248.37,633.06,1235.76,647.88,1215.13,649.44Z" />
                    <path class="gout-splash"
                        d="M827.05,870.52c-24.45-24.71-24.44-45.4,0-70.47,12.21-.83,23.51-9.68,36-6.72,11.93,2.83,25.85,5.46,29.45,21.4,4.09,18.12-3.91,33.79-15.63,44.92C862.41,873.42,845.52,878.56,827.05,870.52Z" />
                    <path class="gout-splash"
                        d="M1580.25,571.25c-15.13,5.3-30.17,8.64-44.39-3.24-4.45-1.09-6.71-4.42-7.19-9.59-.27-7.18-.93-14.37-.76-21.53.76-30.37,11.28-41.7,41.33-45,7.31,6.19,17.06,4.77,24.32,13C1606.93,519.92,1607,546.35,1580.25,571.25Z" />
                    <path class="gout-splash"
                        d="M1233.23,438.59c-13.72-5.31-18.91-17.3-18.37-33.23.74-21.94,16.24-26,29.5-32.67,14.87,1.3,31.5,1.09,31.91,25.48C1276.69,423.21,1258.64,439.27,1233.23,438.59Z" />
                    <path class="gout-splash"
                        d="M1310.58,485.82c-8.3-2.67-10.4-9.22-9.8-16.68.59-7.21,4.72-12.26,11.14-10.64,6.65,1.68,9.81,7.82,9.32,16C1320.73,483.1,1314,482.53,1310.58,485.82Z" />
                </svg>
            </div>

            <div class="container-login100">
                <div class="wrap-login100 p-t-30 p-b-50">
                    <div class="text-center">
                        <img class="logo"
                            :src="require('@assets/logo-white.svg')"
                            alt="Logo">
                    </div>

                    <form class="login100-form validate-form p-b-33 p-t-5"
                        autocomplete="off" accept-charset="UTF-8">

                        <div class="wrap-input validate-input">
                            <input v-model="celular"
                                v-maska="'(##) # ####-####'"
                                class="input100" type="tel"
                                name="identifier"
                                placeholder="Digite o celular" autocomplete="on"
                                required>
                                <!-- Props para quando login for por e-mail além de celular
                                     autocomplete="username"
                                     autocapitalize="none" -->
                            <v-icon class="input-inset">fas fa-phone</v-icon>
                        </div>

                        <div class="wrap-input validate-input">
                            <input v-model="senha" class="input100"
                                :type="(viewPass)?'text':'password'" name="password"
                                placeholder="Digite a senha"
                                autocomplete="current-password" required
                                @keyup="validatePassword">
                            <v-icon class="input-inset">fas fa-key</v-icon>

                            <v-icon v-if="viewPass" class="view-pass"
                                @click="viewPass = false">visibility</v-icon>
                            <v-icon v-else class="view-pass"
                                @click="viewPass = true">visibility_off</v-icon>
                        </div>

                        <div class="container-login100-form-btn">
                            <!--<button class="login100-form-btn" type="submit"
                                @click.prevent.stop="verifyPassword">Entrar</button>-->
                            <v-btn
                            class="login100-form-btn"
                            :loading="loading"
                            :disabled="loading"
                            color="primary"
                            type="submit"
                            @click.prevent.stop="verifyPassword"
                            >
                            Entrar
                            </v-btn>


                            <div class="form-footer">
                                <a @click.stop.prevent="$router.push({name: 'recuperar_senha'})">Esqueci minha senha</a>

                                <a @click.stop.prevent="$router.push({name: 'cadastro_usuario'})">Cadastrar</a>

                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-icon class="info-icon" v-on="on">fa-info-circle</v-icon>
                                    </template>
                                    <span>Caso você nunca tenha acessado a plataforma web, você precisará criar uma nova senha clicando em Esqueci Minha Senha. Se você é novo por aqui e gostaria de conhecer, pode criar um novo cadastro clicando em Cadastrar.</span>
                                </v-tooltip>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <v-snackbar v-model="snackbar" :multi-line="true" top>
                {{ textSnackbar }}
                <v-btn color="red" text @click="snackbar = false">Fechar</v-btn>
            </v-snackbar>
        </div>
    </v-app>
</template>

<script>
import { maska } from 'maska'
import Utils from '@components/Utils/Utils.vue'


export default {
    name: 'Login',
    components: { },
    directives: { maska },
    mixins: [Utils],
    data: () => ({
        celular: null,
        senha: null,
        snackbar: false,
        textSnackbar: '',
        google_id: '',
        viewPass: false,
        loading: false,
    }),
    mounted() {
        setTimeout(() => (
            document.querySelector('#bg-css svg').classList.add('run-animate')
        ), 500)

        this.inicializeReCaptcha()

        document.querySelector('.login100-form-btn').addEventListener('click', () => {
            setTimeout( () => {
                if (this.loading){
                    this.loading = false
                    this.textSnackbar = "Falha ao efetuar a ação! Tente novamente."
                    this.snackbar = true
                    setTimeout( () => {history.go(0)}, 22000) // em trinta segundos recarrega página pois deve ter havido alguma falha de carregamento
                }
            }, 8000)
        })
    },
    destroyed() {
        this.destroyReCaptcha()
        document.querySelector('.login100-form-btn').removeEventListener('click')
    },
    methods: {
        login: function(token){
            this.loading = true
            grecaptcha.execute(process.env.VUE_APP_GOOGLE_RECAPTCHA_PUBLIC_KEY)
                .then((tokenCaptcha) => {
                    this.$http({
                        method: 'post',
                        url: '/webAuth',
                        data: {
                            celular: this.celular.replace(/[^\d]/g,''),
                            senha: this.senha,
                            google_id: token,
                            grecaptcha: tokenCaptcha,
                        }
                    }).then(response => {
                        this.loading = false
                        if(response.status == 200) {
                            if( response.data.success == true
                        && response.data.token && response.data.refreshToken ) {
                                localStorage.setItem('token', response.data.token)
                                localStorage.setItem('refreshToken', response.data.refreshToken)
                                if(response.data.estabelecimento) {
                                    this.$router.push({ name: 'agenda' })
                                } else {
                                    this.$router.push({ name : 'cadastro_estabelecimento' })
                                }
                            } else if(response.data.error_code == 'user_not_confirmed') {
                                this.$router.push({
                                    name : 'cadastro_usuario',
                                    params: { mode: 'pin', celular: response.data.phone }
                                })
                            } else if(response.data.error_code == 'user_not_authenticated') {
                                this.textSnackbar = 'O usuário ou a senha digitados não estão corretos!'
                                this.snackbar = true
                            } else {
                                this.textSnackbar = "Falha ao efetuar a ação! Tente novamente."
                                this.snackbar = true
                            }
                        } else {
                            this.textSnackbar = "Falha ao efetuar a ação! Tente novamente."
                            this.snackbar = true
                        }
                    }).catch(error => {
                        this.textSnackbar = "Falha ao efetuar a ação! Tente novamente."
                        this.snackbar = true
                    })
                })
        },
        validatePassword: function(){
            // if(!this.senha)
            //     return this.senha = this.senha.slice(0,8)
        },
        verifyPassword: async function(event) {
            let token = await this.generateGoogleId()
            this.login(token)
        },
        generateGoogleId: async () =>{
            return localStorage.getItem('firebaseToken')
        },
    }
}
</script>

<style lang="sass" scoped>
    @import '@assets/css/global-vars.sass'


    html,
    body
        overflow: hidden !important

    a
        color: $darkGray !important

    .logo
        height: 110px
        width: auto
        margin-bottom: 30px

    .login100-form-btn
        background-color: $blue

    .v-icon.input-inset
        position: relative
        display: block
        top: -38px
        left: 30px
        pointer-events: none

    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button
        appearance: none
        margin: 0

    .info-icon
        position: relative
        top: -5px
        font-size: 1.8em

    form
        overflow: hidden

        .container-login100-form-btn
            position: relative
            margin-top: 10px

            > button
                display: inline-block
                width: calc(100% - 52px)

        .form-footer
            position: relative
            display: flex
            justify-content: space-between
            height: 40px
            margin-top: 20px
            padding: 10px 10px 0 20px
            background-color: $lightGray

    .wrap-input .v-icon
        position: absolute
        top: 22px

    .v-tooltip__content
        position: fixed
        width: 370px
        max-width: 90%
        top: 50% !important
        left: 50% !important
        transform: translate(-50%, -50%)

        &.menuable__content__active
            opacity: 1 !important

    .container-login100::before
        background-color: rgba(0, 0, 0, 0.15)

    #bg-css
        position: absolute
        top: 0
        left: 0
        width: 100%
        height: 100%
        overflow: hidden

        background: $flatBlack
        background: linear-gradient( 60deg, $yellow 0%, $orange 75% )

        svg
            min-width: 100%
            min-height: 100%

            path
                fill: $avulso
                opacity: 0
                animation-fill-mode: forwards !important

            &.run-animate
                path
                    .bottom-splash
                        transform-origin: left bottom
                        animation-name: fade, scale
                        animation-duration: 1s

                    &.top-splash
                        transform-origin: right top
                        animation-name: fade, scale
                        animation-duration: .7s
                        animation-delay: .5s

                    &.gout-splash
                        transform-origin: right
                        animation-name: fade
                        animation-duration: .3s
                        animation-delay: .9s

                    &.center-splash
                        transform-origin: center
                        animation-name: fade, scale
                        animation-duration: .3s
                        animation-delay: .7s

    @keyframes scale
        from
            transform: scale(.9)
        to
            transform: scale(1)

    @keyframes fade
        0%
            opacity: 0
        70%
            opacity: 1
        100%
            opacity: 1

    .custom-loader
        animation: loader 1s infinite
        display: flex

    @keyframes loader
        from
            transform: rotate(0)

        to
            transform: rotate(360deg)

    // pulled from original template
    *::v-deep
        .limiter
            width: 100%
            margin: 0 auto

        .container-login100
            width: 100%
            min-height: 100vh
            display: flex
            flex-wrap: wrap
            justify-content: center
            align-items: center
            padding: 15px
            background-repeat: no-repeat
            background-position: center
            background-size: cover
            position: relative
            z-index: 1

        .container-login100::before
            background-color: rgba(0, 0, 0, 0.15)
            content: ""
            display: block
            position: absolute
            z-index: -1
            width: 100%
            height: 100%
            top: 0
            left: 0

        .wrap-login100
            width: 390px
            border-radius: 10px
            overflow: hidden
            background: transparent
            padding-top: 30px
            padding-bottom: 50px

        .login100-form
            width: 100%
            border-radius: 10px
            background-color: #fff
            padding-top: 5px
            margin: 0

        .validate-input
            position: relative
            padding: 10px 0

        form .container-login100-form-btn
            position: relative
            width: 100%
            text-align: center

        .login100-form-btn
            background-color: #00BCD4
            font-size: 18px
            color: #fff
            line-height: 1.2
            text-transform: uppercase
            display: flex
            justify-content: center
            align-items: center
            padding: 0 20px
            min-width: 160px
            height: 42px
            border-radius: 21px
            position: relative
            z-index: 1
            transition: all 0.4s

        .input100
            font-size: 20px
            color: #555555
            line-height: 1.2
            display: block
            width: 100%
            height: 50px
            background: transparent
            padding: 0 10px 0 80px
            transition: all 0.4s

        input,
        button
            outline: none
            border: none

        .view-pass
            position: absolute
            top: 22px
            right: 22px
            cursor: pointer
</style>