<template>
    <v-app id="snackbar">
        <v-snackbar v-model="snackbar" class="snackbar" :color="colorChanged" vertical
            :timeout="timeout" :permanent="permanent" @mouseover="mouseOver" @mouseleave="mouseLeave">
            <span class="content-text" :style="textColorChanged">
                <slot name="message" />
            </span>
            <div id="actions" :class="`d-flex ${actionsFlexClass}`">
                <v-btn v-if="!hideCloseButtom" text @click="snackbar = false">Fechar</v-btn>
                <slot name="actions" :active="snackbar" />
            </div>
        </v-snackbar>
    </v-app>
</template>

<script>
export default {
    name: 'Snackbar',
    components: { },
    props: {
        snackbarFlag: {
            type: Number,
            default: 0,
        },
        permanent: {
            type: Boolean,
            default: false,
        },
        color: {
            type: String,
            default: '#FFF',
        },
        textColor: {
            type: String,
            default: '#000',
        },
        error: {
            type: Boolean,
            default: false,
        },
        hideCloseButtom: {
            type: Boolean,
            default: false,
        },
        justifyActions: {
            type: String,
            default: 'space-between',
        },
    },
    data: () => ({
        snackbar: false,
        timeout: 6000,
        refer2SetTimeout: null,
    }),
    computed: {
        colorChanged: function() {
            return (this.error) ? 'error' : this.color
        },
        textColorChanged: function() {
            return (this.error) ? {"color": '#fff'} :{"color": this.textColor }
            /*return {
                "color": '#dd4',
            }*/
        },
        actionsFlexClass: function() {
            if (!this.$slots.actions)
                return 'justify-end'
            return `justify-${this.justifyActions}`
        },
    },
    watch: {
        snackbarFlag: function() {
            if (this.snackbarFlag === 0)
                this.snackbar = false
            else
                this.snackbar = true
        },
        permanent: function() {
            if (this.permanent) this.timeout = 0
            else this.timeout = 6000
        },
    },
    mounted() {
        if (this.permanent) this.timeout = 0
    },
    methods: {
        mouseOver: function() {
            if (!this.permanent)
                this.timeout = 0
            clearTimeout(this.refer2SetTimeout)
        },
        mouseLeave: function() {
            if (!this.permanent)
                this.refer2SetTimeout = setTimeout(() => {
                    this.snackbar = false
                }, 2000)
        },
    }
}
</script>

<style lang="sass" scoped>
    @import '@assets/css/global-vars.sass'

    .v-expansion-panel-content__wrap > p
        margin: 0.3em 0 0

    .v-snack--vertical ::v-deep.v-snack__content
        margin: 0
        padding: 28px 28px 12px

    ::v-deep .v-snack__content
        padding: 28px 28px 12px

        .content-text
            font-size: 1.4em
            margin-bottom: 10px
            color: #000

        .icon-link
            margin-left: 8px

            .v-icon
                font-size: 1.7em
                vertical-align: middle
                color: $white

        .v-btn
            color: #FFF
            background-color: transparent !Important
        .v-btn:before
            opacity: 1
            background-color: $blue
        .v-btn:hover:before,
        .v-btn:focus::before
            opacity: 0.7

        .v-btn.error:before,
        .v-btn.danger:before
            background-color: $red !Important

        .v-btn.success:before,
        .v-btn.primary:before
            background-color: $greenLight !Important

        .v-btn.warning:before
            background-color: $orange !Important

        #actions
            margin-top: 15px

            > *
                // display: inline-block
                margin: 0 10px

                &:first-child
                    margin-left: 0

                &:last-child
                    margin-right: 0


    ::v-deep .v-snack__wrapper.error
        background-color: $orange

        .v-btn:before,
        .v-btn:hover:before,
        .v-btn:focus::before
            background-color: $darkGray
</style>